.content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.drawer > div::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.drawer > div::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
}
.drawer > div::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}

@media {
  .drawer > div::-webkit-scrollbar {
    margin-top: 56px;
  }

  .drawer > div::-webkit-scrollbar-track {
    margin-top: 56px;
  }

  .drawer > div::-webkit-scrollbar-thumb {
    margin-top: 56px;
  }
}

@media (min-width: 600px) {
  .drawer > div::-webkit-scrollbar {
    margin-top: 64px;
  }

  .drawer > div::-webkit-scrollbar-track {
    margin-top: 64px;
  }

  .drawer > div::-webkit-scrollbar-thumb {
    margin-top: 64px;
  }
}